// tslint:disable-next-line: max-line-length
import { Inject, Injectable, Injector } from '@angular/core';
import { DwCoreConfig, DwAuthGuardService, DwAuthenticationType, DwConfig, DwUIMetaDataConfigDefaults, DwConfigServiceToken, DwUIConfig, DwAgGridComponent, DwUIMetaDataConfig, DwConfigProviderBase, DwUiConfigName, DwCoreConfigDefaults, DwAttributeType, DwControlType, DwMediaConfigName, DwMediaConfig, DwFormDebugService, DwLogSeverity, DwUiConfigInitDecorator, DwUiConfigRegistryService, DwConfigService, DwUiRegistryType, DwUiGridStyleReference, DwMenu, DwBreadcrumbMenu, DwSecurityState } from '@devwareapps/devware-cap';
import { environment } from 'src/environments/environment';
import { AppPermissions } from './meta-data/app-permissions.enum';
import { EnvironmentConfigItem } from 'src/environments/models/environment-config.model';
import { AdminManagerService } from './features/admin/services/admin-manager.service';
import { AviatorOnlineUserTypeIds } from './features/shared/models/aviator-user-type.enum';
import { EventManagementService } from './features/shared/util/event-management.service';
import { AppMetaDataItemNames } from './meta-data/app-meta-data.service';

@DwConfig()
@Injectable()
export class AppDevwareConfigProviderService extends DwConfigProviderBase {


    constructor(
        private uiConfigRegistyService: DwUiConfigRegistryService,
        @Inject(DwConfigServiceToken) private dwConfigService: DwConfigService,
        private eventManagementService: EventManagementService,
        private injector: Injector
        // private adminManagerService: AdminManagerService,
    ) {
        super();
        // this.setupRegistrations();

        this.setupReplicatedEvents();
    }

    setupReplicatedEvents() {
        this.eventManagementService.addEventReplicator({
            itemName: AppMetaDataItemNames.FlightSchool,
            replicateItemNames: [AppMetaDataItemNames.FlightSchoolBranding, AppMetaDataItemNames.DwTheme, AppMetaDataItemNames.Course]
        });


        this.eventManagementService.addEventReplicator({
            itemName: AppMetaDataItemNames.DwUser,
            replicateItemNames: [AppMetaDataItemNames.DwUserRole, 'dw_SecurityAudit', AppMetaDataItemNames.Student]
        });
    }

    configureCoreConfig(dwCoreConfig: DwCoreConfig) {
        // Set Core configuration values
        dwCoreConfig.baseTitle = environment.baseTitle;
        dwCoreConfig.apiRoot = environment.apiRoot;

        const environments: EnvironmentConfigItem[] = (environment as any).environments;

        if (environments) {
            const environmenyKey = (environment as any).environmentKey;
            const currentEnvItem = environments.find(e => e.envKey == environmenyKey);

            if (currentEnvItem) {
                dwCoreConfig.apiRoot = currentEnvItem.apiRoot;

                if (currentEnvItem.cdnRoot) {
                    environment.cdnRoot = currentEnvItem.cdnRoot;
                }
            }
        }


        dwCoreConfig.tenantKey = (environment as any).tenantKey;

        if (environment.environmentMessage == 'Dev') {
            DwFormDebugService.showAllMessages = false;
            DwFormDebugService.componentNameList = ['Training', 'DwContextConfigurationBase', 'DwMetadataFormBuilderService'];
            DwFormDebugService.componentNameList = [];
            DwFormDebugService.disableLogging = true;
        }

        // configure logging
        dwCoreConfig.loggingConfig.writeAllToConsole = (environment as any).enableConsoleLogging;

        dwCoreConfig.loggingConfig.serverMessagePrefix = 'Client: ';
        dwCoreConfig.loggingConfig.writeToServerLevels = [DwLogSeverity.error];


        // Override the default CanActivate guard
        dwCoreConfig.routingConfig.defaultCanActivateGuard = DwAuthGuardService;
        dwCoreConfig.routingConfig.googleAnalyticsTrackingToken = environment.googleAnalyticsTrackingToken;

        // Setup security anomymous access
        dwCoreConfig.securityConfig.allowAnonymousAccess = true;
        dwCoreConfig.securityConfig.securityTokenTimeoutMinutes = 5;

        dwCoreConfig.securityConfig.autoLoginAfterRegistration = true;

        dwCoreConfig.securityConfig.alwaysShowRouterOnPathsFunc = (path: string, securityState: DwSecurityState) => {
            return !securityState.isAuthenticated;
        }

        // dwCoreConfig.securityConfig.userInactivitiy = {
        //     warningTimeoutMinutes: 1,
        //     warningMessage: 'You will be logged out in 5 minutes due to inactivity.',
        //     logoutTimeoutMinutes: 2,
        //     logoutMessage: 'You have been logged out due to inactivity.',
        // }

        dwCoreConfig.routingConfig.defaultRouteUrl = '';;

        dwCoreConfig.autoUpdateConfig.checkforAngularUpdates = true;
        dwCoreConfig.autoUpdateConfig.updateMessageTooltip = 'Refresh your browser to get the latest version of the app';

        dwCoreConfig.securityConfig.alwaysShowRouterOnLoginPaths = ['login', ''];

        dwCoreConfig.securityConfig.showAuthenticationMessage = (path: String, securityState: DwSecurityState) => {
            //console.log(`showAuthenticationMessage - ${securityState.authenticationType}`, securityState);

            switch (securityState.authenticationType) {
                case DwAuthenticationType.login:
                    return false;
                case DwAuthenticationType.init:
                    if (!securityState.isAuthenticated) {
                        return false;
                    }
            }

            return true;
        };

        //dwCoreConfig.autoUpdateConfig.updateMessage = 30;

        if (!environment.disabledUserInactvityTimeout) {
            dwCoreConfig.securityConfig.userInactivitiy = {
                warningTimeoutMinutes: 15,
                warningMessage: 'You will be logged out in 2 minutes due to inactivity.',
                logoutTimeoutMinutes: 13,
                logoutMessage: 'You have been logged out due to inactivity.',
            }

            dwCoreConfig.securityConfig.userInactivitiyPermissionOveride = {};

            dwCoreConfig.securityConfig.userInactivitiyPermissionOveride[AppPermissions.lMSNoSessionLogout] = {
                warningTimeoutMinutes: 38,
                warningMessage: 'You will be logged out in 2 minutes due to inactivity.',
                logoutTimeoutMinutes: 40,
                logoutMessage:  'You have been logged out due to inactivity.',
            }

        }
    }

    configureAdditionalConfig(configName: string, config: any, dwCoreConfig: DwCoreConfig) {
        switch (configName) {
            case DwUiConfigName:
                this.configureUIConfig(config, dwCoreConfig);
                break;

            case DwUIMetaDataConfigDefaults.configName:
                this.configureUIMetaDataConfig(config);
                break;

            case DwMediaConfigName:
                this.configureMediaConfig(config, dwCoreConfig);
        }
    }


    private configureUIConfig(dwUiConfig: DwUIConfig, dwCoreConfig: DwCoreConfig) {
        // Setup Contact us menu
        dwUiConfig.showContactUs = environment.showContactUs;
        dwUiConfig.contactUsEMail = environment.contactUsEmail;

        dwUiConfig.gridComponent = DwAgGridComponent;
        dwUiConfig.pageFooterHeight = 0;
        dwUiConfig.showFooter = false;

        dwUiConfig.allowConfiguration = environment.allowConfiguration;

        dwCoreConfig.globals.apiRoot = dwCoreConfig.apiRoot;
        dwCoreConfig.globals.defaultAvatar = dwUiConfig.defaultAvatarImageUrl;
        //dwCoreConfig.securityConfig.

        dwUiConfig.defaultBackgroundImageUrl = 'assets/pilexos/default_banner.png';
        dwUiConfig.emailValidationRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;

        dwUiConfig.defaultAvatarImageUrl = 'assets/img/user-avatar.png';

        // Customize the registration process
        dwUiConfig.registrationConfig = {
            registerTitle: 'Sign up for an account',
            registerButtonText: 'Sign up',
            registrationComponentKey: 'aviator-online-user-registration-extension',
            registrationShowAsLink: true,
            registrationLinkText: `Don't have an account?`,
            useFloatLabelOnLogin: true,
            userNameText: 'Email',
            registrationHideUserName: true
        }

        dwUiConfig.breadcrumbConfig = {
            hideMobile: (breadcrumbs, currentBreadcrumb, currentIndex) => {
                if (currentBreadcrumb?.display == 'Lesson') {
                    return true;
                }
            }
        }

        dwUiConfig.securityInfoConfig = {
            showWelcome: false,
            getNameDisplay: (user, emulating, config, nameOnly) => {
                if (!user) {
                    return '';
                }

                let currentUserDisplay = user.FirstNameLastName;

                if (!nameOnly) {
                    if (config.showWelcome) {
                        return 'Welcome ' + currentUserDisplay;
                    }
                    if (emulating) {
                        currentUserDisplay = user.FirstNameLastName;
                        return 'Emulating ' + currentUserDisplay;

                    }
                }

                return currentUserDisplay;
            },
            getAdditionalLine: (user, emulating, config) => {
                const adminManagerService = this.injector.get(AdminManagerService);

                const aviatorUser = adminManagerService.getAviatorUser(user);

                switch (aviatorUser?.UserTypeId) {
                    case AviatorOnlineUserTypeIds.flightSchool:
                        return 'Flight School';
                    case AviatorOnlineUserTypeIds.student:
                        return 'Student';
                    case AviatorOnlineUserTypeIds.flightInstructor:
                        return 'Instructor';
                    case AviatorOnlineUserTypeIds.aviatorOnlineAdmin:
                        return 'LMS Admin';
                }
            }
        }


        dwUiConfig.enableThemes = true;


    }

    private configureUIMetaDataConfig(dwUiMetaDataConfig: DwUIMetaDataConfig) {
        // Default date/time configuration
        dwUiMetaDataConfig.useGmtDateTime = true;
        dwUiMetaDataConfig.use24HourTime = false;
        dwUiMetaDataConfig.pdfRenderWaitTimeMs = 1200;
        dwUiMetaDataConfig.emailValidationRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;;

        dwUiMetaDataConfig.gridDefaultPreferences.rowHeight = 25;

        dwUiMetaDataConfig.userContextExtendedAttributes = [
            { name: 'UserTypeId', display: 'User Type', attrType: DwAttributeType.Integer, controlType: DwControlType.DropDownList, lookupId: 137 }
        ];
    }

    configureMediaConfig(config: DwMediaConfig, dwCoreConfig: DwCoreConfig) {
        config.defaultMaxFileSizeMb = 10;

        config.cdnSettings = {
            useCDN: environment.useCDN,
            cdnUrl: environment.cdnRoot,
        }
    }



    @DwUiConfigInitDecorator({
        name: 'AviatorUiConfigInit',
        useDI: true,
    })
    setupRegistrations() {
        // this.setupContainerStyles();
        // this.setupButtonContainerStyles();
        this.setupGridStyles();
        // this.setupListStyles();
        // this.registerComponents();
    }

    private setupGridStyles() {

        this.uiConfigRegistyService.registerItem(DwUiRegistryType.gridStyles, <DwUiGridStyleReference>{
            keyName: 'aviator-large',
            display: 'Aviator - large',
            containerClass: 'dw-grid-container dw-grid-font-large aviator-large-grid',
            filterHeaderClass: 'dw-grid-filter-header',
            filterBodyClass: 'dw-grid-filter-body',
            actionBarClass: 'dw-grid-action-bar',
            rowHeight: 50,
        });

    }
}