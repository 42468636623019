import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwModalConfig, DwModalHandler, DwModalResult } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { AppMetaData, AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { AdminManagerService } from '../../services/admin-manager.service';
import { map } from 'rxjs/operators';
import { AviatorUser } from '../../../shared/models/aviator-user.model';

@Component({
  selector: 'app-school-selection-modal',
  template: `
    <div dwRow>
      <div dwCol="12">
        <label>Flight School</label>
          <dw-select-lookup [lookupId]="fligtSchoolLookupId" [(ngModel)]="flightSchoolId" showBlankOption="false"></dw-select-lookup>
      </div>
    </div>
  `
})
export class SchoolSelectionModalComponent implements OnInit, DwModalHandler {
  fligtSchoolLookupId = AppMetaDataLookups.FlightSchoolAllItems;
  aviatorUser: AviatorUser;
  constructor(private adminManagerService: AdminManagerService) { }
  modalConfig: DwModalConfig<any>;

  closeModal = new EventEmitter<DwModalResult<any>>();
  flightSchoolId: any;


  
  ngOnInit(): void {
    this.aviatorUser = this.adminManagerService.getAviatorUser();

    this.flightSchoolId = this.aviatorUser.FlightSchoolId;

  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult> {
    let result = new DwModalResult();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:

      if (!this.flightSchoolId) {
        return;
      }

      return this.adminManagerService.setCurrentFlightSchool(this.flightSchoolId)
        .pipe(map(() => {

          result.closeModal = true;

          return result;
        }));

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }


}
