import { Component, Inject, OnInit } from '@angular/core';

import { AppMetaDataItemNames, AssignmentEntity, InstructorEntity, StudentCourseEntity } from '../../../../meta-data/app-meta-data.service';
import {
  DwComponent, DwComponentType, DwExpressionService, DwFormContextMode, DwMdFormContextInfo, DwMetaDataFormApi, DwMetaDataFormStateService,
  DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken,
  DwLookupSearchType, DwOrmDataService, DwOrmDataServiceToken, DwQueryHelper, DwQueryFilter, DwOperator, DwFilterType,
  deepCopy,
  DwEventService,
  DwGridEvents,
  DwFormActionInterceptor,
  DwFormActionInterceptResult,
  DwFormActionKeys
}
  from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SchoolRepositoryService } from '../../services/school-repository.service';
import { InstructorRepositoryService } from '../../../instructors/services/instructor-repository.service';


@DwComponent({
  key: 'assignCounrseUtil',
  name: 'Assign Course Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.StudentCourse,
})
@Component({
  selector: 'app-assign-course-util',
  template: ``
})
export class AssignCourseUtilComponent extends DwSectionBaseComponent {
  assignment: AssignmentEntity;

  originalAssignment: AssignmentEntity;
  formApi: DwMetaDataFormApi;
  

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService,
    private schoolRepositoryService: SchoolRepositoryService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupFormActionInterceptor();

    this.dwFormStateService.addActionInterceptor(this.formActionInterceptor);
  }

  formActionInterceptor: DwFormActionInterceptor

  setupFormActionInterceptor() {
    this.formActionInterceptor = {
      interceptorName: 'assignCourseSaveInterceptor',
      order: 10,
      onBeforeAction: (actionPref, actionConfig) => {
        const result: DwFormActionInterceptResult = {
          abort: false
        }

        if (actionPref.actionKey != DwFormActionKeys.formSave) {
          return of(result);
        }

        return this.saveStudentCourses();
      }
    }
  }

  private saveStudentCourses(): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false
    }

    const studentCourse = this.formApi.getFormData<StudentCourseEntity>();

    if (!studentCourse.StudentId) {
      result.abort = true;
      result.message = 'Student was not found';
      return of(result);
    }

    const variableContext = this.formApi.getVariableContextValue<StudentCourseVariableContext>();

    if (!this.validateForm(variableContext, result)) {
      return of(result);
    }

    if (variableContext.Add_Course_Package) {
      return this.saveCoursePackages(studentCourse.StudentId, variableContext);
    }

    const courseIds = variableContext.Courses.split(',').map(c => parseInt(c));

    return this.saveCourses(studentCourse.StudentId, courseIds); 
  }

  saveCourses(studentId: number, courseIds: number[]): Observable<DwFormActionInterceptResult> {

    return this.schoolRepositoryService.assignCourses(studentId, courseIds)
      .pipe(map(savedStudentCourses => {

        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.DwUser), {});
        if (savedStudentCourses.length == 0) {
          return {
            abort: true,
            message: 'Selected courses are already assigned'
          }
        }
        
        this.formApi.cancel();

        return {
          abort: true,
          message: 'Selected courses are already assigned'
        }
      }));
  }

  saveCoursePackages(studentId: number, variableContext: StudentCourseVariableContext): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false
    }
    
    const coursePackageIds = variableContext.Course_Package.split(',').map(c => parseInt(c));

    return this.schoolRepositoryService.getCoursePackages()
      .pipe(mergeMap(packages => {
        const courseIds: number[] = [];

        for (const packageId of coursePackageIds) {
          const packageCourses = packages.find(p => p.PackageId == packageId)?.PackageCourse;

          if (!packageCourses) {
            continue;
          }

          for(const course of packageCourses) {
            if (courseIds.indexOf(course.CourseId) == -1) {
              courseIds.push(course.CourseId);
            }
          }
        }

        return this.saveCourses(studentId, courseIds);
      }));
  }

  validateForm(variableContext: StudentCourseVariableContext, result: DwFormActionInterceptResult) {
    if (variableContext.Add_Course_Package && (!variableContext.Course_Package || variableContext.Course_Package.length == 0)) {
      result.abort = true;
      result.message = 'Please select at least one course package';
      return false;
    }

    if (!variableContext.Add_Course_Package && (!variableContext.Courses || variableContext.Courses.length == 0)) {
      result.abort = true;
      result.message = 'Please select at least one course';
      return false;
    }

    return true;
  }
}

export interface StudentCourseVariableContext {
  Add_Course_Package?: boolean;
  Course_Package?: string;
  Courses?: string;
}